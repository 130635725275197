/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';
import { DFPSlotsProvider } from 'react-dfp';

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
    console.log(`👍 IntersectionObserver is polyfilled`);
  }
};

export const wrapRootElement = ({ element }) => (
  <DFPSlotsProvider dfpNetworkId='6025' adUnit="festivalsau/splendourxr"><ApolloProvider client={client}>{element}</ApolloProvider></DFPSlotsProvider>
);